$color_default: #050505;
$color_hedgeFund: #17375e;
$color_fundOfFunds: #256eec;
$color_privateCredit: #403152;
$color_privateEquity: #984807;
$color_liquidAlts: #215968;
$color_realAssets: #cd5c5c;
$color_realEstate: #41ab13;
$color_longOnlyEquity: #ff6464;
$color_longOnlyCredit: #deb10b;
$color_coreFixedIncome: #0ba280;
$color_coInvestment: #64bce8;
$color_assetAllocation: #e494bd;
$color_monitorFundComposite: #838be8;
$color_publicCredit: #79bccc;
$color_publicEquity: #55695f;
$color_longOnly: #da470e;
$color_secondaries: #046f2f;

[class*="asset-class"] {
    border-radius: 3px;
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: $color_default;

    &:after {
        font-size:24px;
        color: #fff;
        content:'%';
    }
}

.asset-class-HedgeFund {
    &:after {
        content:'hf';
    }
    background-color: $color_hedgeFund;
}

.asset-class-FundOfFunds {
    &:after {
        content:'ff';
    }
    background-color: $color_fundOfFunds;
}

.asset-class-PrivateCredit,
.asset-class-ClosedEnd {
    &:after {
        content:'pc';
    }
    background-color: $color_privateCredit;
}

.asset-class-PrivateEquity {
    &:after {
        content:'pe';
    }
    background-color: $color_privateEquity;
}

.asset-class-LiquidAlts {
    &:after {
        content:'la';
    }
    background-color: $color_liquidAlts;
}

.asset-class-RealAssets {
    &:after {
        content:'ra';
    }
    background-color: $color_realAssets;
}

.asset-class-RealEstate {
    &:after {
        content:'re';
    }
    background-color: $color_realEstate;
}

.asset-class-LongOnlyEquity {
    &:after {
        content:'le';
    }
    background-color: $color_longOnlyEquity;
}

.asset-class-LongOnlyCredit {
    &:after {
        content:'lc';
    }
    background-color: $color_longOnlyCredit;
}

.asset-class-CoreFixedIncome {
    &:after {
        content:'ci';
    }
    background-color: $color_coreFixedIncome;
}

.asset-class-Co-Invest {
    &:after {
        content:'ci';
    }
    background-color: $color_coInvestment;
}

.asset-class-AssetAllocation {
    &:after {
        content:'aa';
    }
    background-color: $color_assetAllocation;
}

.asset-class-MFC {
    &:after {
        content:'mc';
    }
    background-color: $color_monitorFundComposite;
}

.asset-class-PublicCredit {
    &:after {
        content:'pc';
    }
    background-color: $color_publicCredit;
}

.asset-class-PublicEquity {
    &:after {
        content:'pe';
    }
    background-color: $color_publicEquity;
} 

.asset-class-LongOnly {
    &:after {
        content:'lo';
    }
    background-color: $color_longOnly;
}








