a {
  color: $o2_color_link;
}

.text-center {
  text-align: center;
}

page-header {
  & .subtitle {
    > * {
      font-size: 12px;
    }
    > *:not(a) {
      color: #aba9a9;
    }
  }
  &.stuck {
    border-bottom: 6px solid #696d7d;
    background-color: #ffffff;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 45%);
    border-left: 1px solid #696d7d;
    border-right: 1px solid #696d7d;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .framed {
      box-shadow: $box-shadow-crisp;
    }
  }
}

page-navigation {
  position: fixed;
  top: 130px;
  right: 5px;
}

section {
  .section {
    scroll-margin-top: 75px;
  }
}

row {
  position: relative;
}

.subtitle {
  display: grid;
  grid-template-rows: 20px;
  grid-auto-columns: max-content;
  > * {
    padding: 0 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  text-overflow: ellipsis;
  width: 650px;
}

.external-marker {
  position: relative;
}

text-v2:not(:hover),
number-v2:not(:hover) {
  .ui-toolbar {
    display: none;
  }
}

button:disabled {
  opacity: 0.5;
}

.popover {
  .header.summary {
    display: grid;
    justify-items: center;

    &.fund {
      &.open {
        grid-template-columns: repeat(6, 1fr);
      }
      &.closed {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &.company {
      grid-template-columns: repeat(5, 1fr);
    }

    .sub-summary {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      justify-items: center;
      gap: 5px;
    }

    &.col3 {
      grid-template-columns: repeat(3, 1fr);
    }
    .handle {
      position: relative;
      height: 40px;
      width: 70px;
      border-radius: 3px;
      border: 1px solid transparent;
      color: #fff;
      &:not(.multi) {
        background-color: $o2_color_fade;
      }
      &.Active {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }

      &.Saved:not(.multi) {
        background-color: $o2_color_success;
      }
      &.Saving:not(.multi) {
        background-color: $o2_color_warning;
      }
      &.HasError:not(.multi),
      &.NotSaved:not(.multi) {
        background-color: $o2_color_error;
      }
      &.IsDirty:not(.multi) {
        background-color: $o2_color_main;
      }

      &::after {
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:disabled {
        cursor: default;
      }
      &.Company {
        &::after {
          content: 'Company';
        }
      }
      &.Programs {
        &::after {
          content: 'Programs';
        }
      }
      &.Program {
        &::after {
          content: 'Program';
        }
      }
      &.ProgramAUM {
        &::after {
          content: 'Program AUM';
        }
      }
      &.Fund {
        &::after {
          content: 'Fund';
        }
      }
      &.AUM {
        &::after {
          content: 'AUM';
        }
      }
      &.publicreturns {
        &::after {
          content: 'Returns';
        }
      }
      &.netIRR {
        &::after {
          content: 'Net IRR';
        }
      }
      &.grossIRR {
        &::after {
          content: 'Gross IRR';
        }
      }
      &.netTVPI {
        &::after {
          content: 'Net TVPI';
        }
      }
      &.grossTVPI {
        &::after {
          content: 'Gross TVPI';
        }
      }
      &.netDPI {
        &::after {
          content: 'Net DPI';
        }
      }
      &.netRVPI {
        &::after {
          content: 'Net RVPI';
        }
      }
      &.investedCapital {
        &::after {
          content: 'Invested Capital';
        }
      }
      &.netIncomeReturn {
        &::after {
          content: 'Net Income';
        }
      }
      &.grossIncomeReturn {
        &::after {
          content: 'Gross Income';
        }
      }
      &.netAppreciationReturn {
        &::after {
          content: 'Net Appr.';
        }
      }
      &.grossAppreciationReturn {
        &::after {
          content: 'Gross Appr.';
        }
      }
      &.totalNetReturn {
        &::after {
          content: 'Total Net';
        }
      }
      &.totalGrossReturn {
        &::after {
          content: 'Total Gross';
        }
      }
      &.Classes {
        &::after {
          content: 'Classes';
        }
      }
      &.Notes {
        &::after {
          content: 'Notes';
        }
      }
      &.multi {
        position: relative;
        display: grid;
        grid-template-rows: 1fr;

        span {
          display: block;
          height: 100%;
          background-color: $o2_color_fade;
          &.first {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          &.last {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
        span.HasError,
        span.NotSaved {
          background-color: $o2_color_error;
        }
        span.Saved {
          background-color: $o2_color_success;
        }
        span.Saving {
          background-color: $o2_color_warning;
        }
      }
    }

    > *.r2s1 {
      font-size: 12px;
      color: grey;
      &.Saved {
        color: $o2_color_success;
      }
      &.Saving {
        color: $o2_color_warning;
      }
      &.HasError,
      &.NotSaved {
        color: $o2_color_error;
      }
      &.IsDirty {
        color: $o2_color_main;
      }
    }

    .dot {
      content: ' ';
      width: 10px;
      height: 10px;
      border-radius: 40px;
      background-color: lightgrey;
    }
  }
  .content.details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-height: 200px;
    height: inherit;
    background-color: lightgrey;
    div {
      padding: 10px;
    }

    .c1s1 {
      justify-self: center;
      align-self: center;
    }

    .c1s4 {
      user-select: all;
    }

    .c2s1,
    .c3s1 {
      justify-self: left;
      align-self: center;
      font-size: 12px;
    }
    .c2s1 {
      font-weight: 500;
    }
    .c3s1 {
      color: $o2_color_error;
    }

    &.max-width {
      width: 1029px;
    }
  }
  .footer.message {
    display: grid;
    grid-template-columns: auto, 20px;
    justify-content: inherit;
    .message {
      font-size: 11px;
      align-self: center;
      justify-content: flex-start;
      white-space: normal;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 20px;
    > button + button {
      margin-left: 5px;
    }
    > button {
      box-shadow: none;
    }
  }
}

.sticky-message {
  width: 400px;
  position: fixed;
  top: 200px;
  margin: auto calc(50% - 200px);
  background-color: rgba(231, 231, 231, 0.9);
  font-size: 14px;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 14%);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 5px;
  border: 1px Solid #ededed;
  z-index: 1000000;
  > .header.warning {
    padding: 20px 20px 0px;
    color: #f7a000;
  }
  > .content {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    border-radius: 3px;
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

history {
  cursor: pointer;
  &[mode='timeline'] {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    height: 110px;
  }
}
