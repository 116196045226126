$o2_color_main: rgba(105, 109, 125);
$ο2_color_alt: rgba(232,232,232);
$o2_color_fade: rgba(105, 109, 125, 0.5);
$o2_color_fader: rgba(105, 109, 125, 0.2);
$o2_color1_bkg: #f5f5f5; //rgb(232,232,232);
$o2_color1_bkg2: rgb(255,255,255);
$o2_color_warning: rgba(247,160,0);
$o2_color_warning_fade: rgba(247,160,0, 0.2);
$o2_color_error:  #a2352d;
$o2_color_error_fade:  #e0bcba;
$o2_color_nodata: rgba(187, 187, 187, 0.439);
$o2_color_disabled: rgb(218, 216, 216);
$o2_color_open_end: rgba(105, 109, 125);
$o2_color_closed_end: rgb(146, 125, 171);
$o2_color_success: #2da22d;
$o2_color_link: #3b9ae4;

