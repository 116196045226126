@use "sass:map";

/*#region - Global Variables - */

//---- O2 Font Props ----
$font-family: Roboto, "Helvetica Neue", sans-serif;
$font-size: 14px;
$font-weight: 400;
$line-height: 20px;


//---- O2 Theme Colors ----

$o2_color_main: rgba(105, 109, 125);
$ο2_color_alt: rgba(232,232,232);
$o2_color_fade: rgba(105, 109, 125, 0.5);
$o2_color_fader: rgba(105, 109, 125, 0.2);
$o2_color1_bkg: #f5f5f5; //rgb(232,232,232);
$o2_color1_bkg2: rgb(255,255,255);
$o2_color_warning: rgba(247,160,0);
$o2_color_warning_fade: rgba(247,160,0, 0.2);
$o2_color_error:  #a2352d;
$o2_color_error_fade:  #e0bcba;
$o2_color_nodata: rgba(187, 187, 187, 0.439);
$o2_color_disabled: rgb(218, 216, 216);
$o2_color_open_end: rgba(105, 109, 125);
$o2_color_closed_end: rgb(146, 125, 171);
$o2_color_success: #2da22d;


//---- O2 Theme Colors END ----

//---- Investment Vehicle ----

$iv-color-hf: #17375e;
$iv-color-pc: #403152;
$iv-color-pe: #984807;
$iv-color-re: #41ab13;
$iv-color-ra: #cd5c5c;

//---- Investment Vehicle END ----

//---- O2 Element Distances ----

$pad_left: 3px;
$pad_right: 0px;
$pad_top: 15px;
$pad_bottom: 0px;

//---- O2 Element Distances END ----

//---- O2 General Globals ----

$ctrl-margin: 7px;
$ctrl-padding: 10px;
$border-radius: 3px;
$box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
$box-shadow-crisp: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
$box-shadow_wide: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

/*#endregion - Global Variables - */

@mixin o2-cols($units) {
    @for $c from 1 to $units + 1 {
        .cols#{$c} {
            grid-template-columns: repeat($c, 1fr);
        }
    }
}

@mixin o2-rows($units) {
    @for $r from 1 to $units + 1 {
        .rows#{$r} {
            grid-template-rows: repeat($r, 1fr);
        }
    }
}

@mixin o2-col($units) {
    @for $c from 1 to $units + 1 {
        @for $s from 1 to $units + 1 {
            .c#{$c}s#{$s} {
                grid-column: $c / span $s;
            }
        }
    }
}

@mixin o2-row($units) {
    @for $r from 1 to $units + 1 {
        @for $s from 1 to $units + 1 {
            .r#{$r}s#{$s} {
                grid-row: $r / span $s;
            }
        }
    }
}

@mixin o2-size($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .size-#{nth($selectors, $i + 1)} {
            height: #{nth($selectors, $i + 1)}#{$unit};
            width: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-height($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .height-#{nth($selectors, $i + 1)} {
            height: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-width($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .width-#{nth($selectors, $i + 1)} {
            width: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-width-per($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .width-p#{nth($selectors, $i + 1)} {
            width: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-max-width($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .max-width-#{nth($selectors, $i + 1)} {
            max-width: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-min-width($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .min-width-#{nth($selectors, $i + 1)} {
            min-width: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-line-height($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .line-height-#{nth($selectors, $i + 1)} {
            height: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-font-size($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .font-size-#{nth($selectors, $i + 1)} {
            font-size: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-pad-left($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .pad-left-#{nth($selectors, $i + 1)} {
            padding-left: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-pad-right($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .pad-right-#{nth($selectors, $i + 1)} {
            padding-right: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-pad-top($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .pad-top-#{nth($selectors, $i + 1)} {
            padding-top: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-pad-side($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .pad-side-#{nth($selectors, $i + 1)} {
            padding-left: #{nth($selectors, $i + 1)}#{$unit};
            padding-right: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-pad($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .pad-#{nth($selectors, $i + 1)} {
            padding: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-margin($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .margin-#{nth($selectors, $i + 1)} {
            margin: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-margin-bottom($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .margin-bottom-#{nth($selectors, $i + 1)} {
            margin-bottom: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-margin-right($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .margin-right-#{nth($selectors, $i + 1)} {
            margin-right: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-margin-side($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .margin-side-#{nth($selectors, $i + 1)} {
            margin-right: #{nth($selectors, $i + 1)}#{$unit};
            margin-left: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-mar-v($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .mar-v-#{nth($selectors, $i + 1)} {
            margin-bottom: #{nth($selectors, $i + 1)}#{$unit};
            margin-top: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-right($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .right-#{nth($selectors, $i + 1)} {
            right: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-top($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .top-#{nth($selectors, $i + 1)} {
            top: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-bottom($unit,$selectors...) {
    @for $i from 0 to length($selectors) {
        .bottom-#{nth($selectors, $i + 1)} {
            bottom: #{nth($selectors, $i + 1)}#{$unit};
        }
    }
}

@mixin o2-color($num-colors) {
    @for $i from 1 to $num-colors {
        $color: rgb(0, random(200), random(200), 0.7);
        .o2-color-#{$i} {
            background-color: $color;
            filter: saturate(0.5);
            //color: $color;
        }
        .o2-color-indicator-#{$i} {
            &:after{
                border-top-color:$color;
                filter: saturate(0.5);
            }
        }
    }
}

@mixin z($num-indicies) {
    @for $i from 1 to $num-indicies {
        .z#{$i} {
            z-index: #{$i};
        }
    }
}


