/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/overlay-prebuilt.css';
@import './mixins.scss';
@import 'asset_classes.scss';
@import 'controls_ui_common.scss';
@import 'controls_ui_o2.scss';
@import 'theme_o2.scss';
@import 'styles_o2.scss';

@include o2-col(40);
@include o2-cols(100);
@include o2-row(3);

@include o2-width-per(
  '%',
  2,
  4,
  5,
  7,
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  19,
  20,
  21,
  23,
  25,
  26,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  40,
  45,
  50,
  53,
  55,
  56,
  60,
  65,
  68,
  70,
  75,
  76,
  80,
  87,
  90,
  100
);
@include o2-width('px', 14, 20, 22, 58, 100, 125, 130, 135, 150, 400);
@include o2-margin-side('px', 1);
@include o2-margin-right('px', 25);
@include z(3);
/* @include z(1,2,3,4); */

/*#region - HTML Generic Overrides - */

html,
body {
  height: 100%;
}

body {
  /* background-color: $o2_color1_bkg; */
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $o2_color_main;
  margin: 0;
  cursor: default;
  user-select: none;
}

body {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

button {
  font-family: inherit;
  &:focus {
    outline: none;
  }
  background-color: transparent;
  border: none;
  background-image: none;
  cursor: pointer;
  padding: 0;
  box-sizing: inherit;
}

input {
  font-family: inherit;
  &:focus {
    outline: none;
  }
  background-color: transparent;
  border: none;
  background-image: none;
  padding: 0;
}

textarea {
  font-family: inherit;
  border: none;
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3 {
  font-family: inherit;
  font-weight: inherit;
  padding: 0px;
  margin: 0px;
}

hr.inRow {
  width: 100%;
  opacity: 0.3;
  margin: 30px 0px 20px 0px;
}

.pointer {
  cursor: pointer;
}

.help {
  cursor: help !important;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
  column-gap: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.cdk-overlay-backdrop {
  background-color: #fff;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.75;
}

.cdk-overlay-pane:has(.summary) {
  top: 20px !important;
  left: calc(50% - 536px) !important;
  bottom: unset !important;
}

/*#endregion - HTML Generic Overrides - */

/*#region - Global Styles - */

.toolbar {
  .svg-inline--fa {
    vertical-align: -0.25em;
  }
  * {
    display: flex;
  }
}
.link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.labelInfo,
.sectionGroupInfo {
  cursor: help !important;
}
.info {
  position: relative;
  &.warning {
    color: $o2_color_warning;
  }
  &.error {
    color: $o2_color_error;
  }
  &.intention {
    &:not(.warning) {
      &:after {
        box-shadow: inset 0 -1.3vw 0 0 $o2_color_fade;
      }
    }
    &.warning {
      &:after {
        box-shadow: inset 0 -1.3vw 0 0 $o2_color_warning;
      }
    }
    &:after {
      content: '';
      opacity: 0px;
      position: absolute;
      left: 0;
      bottom: -1px;
      /* transition: transform 2s; */
      transform: scale(0);
      width: 100%;
      height: 2px;
      transform-origin: left;
      animation-name: stretchRight;
      z-index: -1;
    }
    &:hover {
      &:after {
        opacity: 1px;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        transform: scaleX(1);
      }
    }
  }

  &.tag {
    color: $o2_color_fade;
    font-size: 18px;
  }
}

.popover {
  &.normal {
    border-top: 1px Solid $o2_color_main;
  }
  &.warning {
    border-top: 1px Solid $o2_color_warning;
  }
  &.error {
    border-top: 1px Solid $o2_color_error;
  }
}

.item-start {
  margin-right: auto;
  justify-content: flex-start;
}

.item-end {
  margin-left: auto;
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.fixed {
  position: fixed;
}

.arrow-right {
  padding-left: 15px;
  margin-left: 0px;
}
.arrow-right::before,
.arrow-right::after {
  content: '';
  position: absolute;
  left: -1px;
  width: 0px;
  height: 0px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.arrow-right::before {
  top: 13px;
  border-left: 7px solid lightgray;
}
.arrow-right::after {
  left: -2px;
  top: 13px;
  border-left: 6px solid #fff;
}

.bold {
  font-weight: 500;
}

.formatted {
  white-space: pre-wrap;
}

.x-scroll {
  overflow-x: auto;
}

.y-hide {
  overflow-y: hidden;
}

/*#endregion - Global Styles - */

.page > .content.dim {
  .framed:not(.indicate-error) {
    opacity: 0.2;
  }
  section:not(.indicate-error) {
    opacity: 0.2;
  }
}

page-header > .o2-page-header {
  border-top: 2.5px solid transparent;
}

page-header.stuck {
  & > .o2-page-header {
    border-top-color: $o2_color_main;
    position: sticky;
    top: 0px;
    background-color: $o2_color1_bkg2;
    box-shadow: $box-shadow_wide;
    z-index: 1000;
    & .o2-ctrl-page-btn:not(.disabled) {
      box-shadow: $box-shadow-crisp;
    }
  }
}

.o2-page-header {
  & > .o2-page-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.5px 0px 2.5px 0px;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    text-align: left;
    /* transition: position 2s ease-out; */
    max-width: 1100px;

    .column:not(:first-of-type) {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      &.right {
        margin-left: auto;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      > *:not(:first-child) {
        margin-left: $ctrl-margin;
      }
    }

    .symbol {
      background-color: $o2_color_main;
      &.company {
        background-color: #e8e7e7;
        & > fa-icon {
          color: #aba9a9;
        }
      }
      color: #fff;
      border-radius: $border-radius;
      &:not(.small) {
        font-size: 22px;
        padding: 12px 14px;
      }
      &.small {
        font-size: 10px;
        padding: 0px 3px;
        opacity: 0.8;
        line-height: 20px;
        height: 14px;
        display: flex;
        align-items: center;
      }
    }

    .link {
      font-size: 12px;
      /* color: $o2_color_fade; */
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .link-icon {
      font-size: 12px;
      color: $o2_color_fade;
    }

    .small-icon {
      position: relative;
      font-size: 9px;
      background-color: rgba(105, 109, 125, 0.2);
      color: #fff;
      padding: 3px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: -3px;
    }

    .shareclass {
      display: none;
    }

    & > h1.fade {
      color: $o2_color_fade;
    }
  }
}

/*#region - NEW Layout*/

.master-page,
.page,
.page > .content,
.page .view {
  display: grid;
  align-items: center;
}
.page,
.page > .content,
.page .view {
  &:not(.nogap) {
    grid-gap: 8px;
  }
}
.master-page {
  grid-gap: 8px;
  min-height: 100%;
  grid-template-columns: 1fr 1070px 1fr;
  grid-template-rows: 5px 40px auto;
}
.master-theme {
  background-color: $o2_color_main;
}
.master-toolbar {
  o2-search {
    grid-column: 16 / span 7;
    grid-row: 2 / span 1;
  }
}
.master-toolbar.stuck {
  position: sticky;
  top: -4px;
  z-index: 100;
  o2-search {
    grid-column: 24 / span 3;
    .o2-search-list {
      top: 52px;
    }
  }
  badge {
    display: none;
  }
}
.page {
  grid-template-columns: auto;
  grid-template-rows: auto;
}

@-webkit-keyframes h-move {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes h-move {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes h-loading {
  0% {
    background-position: left 100% bottom;
  }

  100% {
    background-position: left -100% bottom;
  }
}
*.top {
  align-self: start;
}
*.center {
  justify-self: center;
}
*.middle {
  align-self: center;
}
*.left {
  justify-self: start;
}
*.right {
  justify-self: end;
}
*.hstretch {
  justify-self: stretch;
}
*.hstretch-min {
  min-width: 100%;
  width: fit-content;
  white-space: nowrap;
  padding: 0 5px;
}
*.vstretch {
  align-self: stretch;
}
*.stretch {
  place-self: stretch;
}

page-header {
  position: sticky;
  z-index: 100;
  top: -4px;

  > .header > .row {
    min-height: 60px;
    align-items: center;
  }

  & .title {
    font-size: 24px;
    height: 30px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 650px;
  }
  .title-icon {
    border-radius: 3px;
    display: grid;
    justify-items: center;
    align-items: center;

    &.company {
      background-color: #d3d3d3;
      color: grey;
      font-size: 22px;
    }
    &.fund {
      background-color: $o2_color_main;
      color: #fff;
      font-size: 18px;
    }
  }
  & dropdown {
    border-bottom: none !important;
    width: 175px;

    > .popup {
      max-height: 400px;
      overflow-y: auto;
    }
    padding-left: 2px;
    > input,
    .ruler {
      font-size: 12px;
      height: auto;
    }
    > span.label,
    span.label.top {
      top: 0px;
      font-size: 12px;
      font-weight: 400;
      color: #aba9a9;
    }
    > .popup {
      top: 20px;
    }
    > .toolbar > fa-icon {
      font-size: 10px;
    }
    &.fund {
      background-color: transparent;
      > input,
      > span.ruler {
        font-size: 24px;
        height: 24px;
      }
      & > .prefix {
        border-right-color: transparent;
        &::before,
        &::after {
          border-width: 7px;
          top: 14px;
        }
      }
      &:not(.stuck) {
        & > .prefix {
          &::before {
            border-left-color: gray;
          }
          &::after {
            border-left-color: $o2_color1_bkg;
          }
        }
      }
      &.stuck {
        & > .prefix {
          &::before {
            border-left-color: lightgray;
          }
          &::after {
            border-left-color: #fff;
          }
        }
      }
    }
  }
  & .drawer::before {
    content: '';
    position: fixed;
    top: 0px;
    width: 100%;
    height: 6px;
    left: 0px;
    background-color: $o2_color_main;
  }
  & .drawer.NotSaved::before {
    background-color: $o2_color_error;
  }
  & .drawer.Saved::before {
    background-color: $o2_color_success;
  }
  & .drawer.Saving::before,
  .drawer.Loading::before {
    background-color: $o2_color_fade;
  }
  &.stuck {
    .drawer {
      display: none;
    }
  }
}

.error-navigation {
  display: grid;
  align-items: center;
  width: 300px;
  height: 40px;
  background-color: $o2_color_error;
  color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  > fa-icon {
    justify-self: stretch;
    align-self: stretch;
    line-height: 40px;
    text-align: center;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

section {
  place-self: stretch;
  margin: 0px;
  &.unstyled > .section {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    border-color: transparent !important;
    > .content {
      padding: 0px;
      > row {
        margin: 0px;
      }
    }
  }
}

row {
  row {
    margin: 0px;
  }
  &.sticky {
    position: sticky;
    background-color: #fff;
    z-index: 10;
    &.bottom {
      bottom: 0px;
    }
    &.top {
      top: 0px;
    }
  }
  & .no-content {
    color: $o2_color_fade;
    font-size: 12px;
  }
}

/* row + row:not([ng-reflect-is-rendered="false"]),
row + section-group:not(.visible),
section-group:not(.visible) + section-group:not(.visible),
section-group:not(.visible) + row {
    margin-top: 10px;
} */

row + section-group.visible,
section-group + section-group.visible:not(.multi),
section-group.visible + row {
  margin-top: 25px;
}
.section-content {
  padding: 0px 20px 0px 0px;
  row {
    margin: 0px;
    > .row {
      margin: 10px 0px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  opacity: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*#endregion - NEW Layout - */

/*#region - NEW Controls*/
dropdown,
date,
text,
number,
location,
.mock-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  & > span.label.disabled,
  & input.disabled {
    opacity: 0.3;
  }

  &.hasError {
    * {
      color: $o2_color_error !important;
    }
  }

  & *.hasError {
    color: $o2_color_error !important;
  }

  & span.label.isDirty {
    &:after {
      content: '*';
    }
  }

  &.slim {
    > span.label {
      top: -3px !important;
      left: 3px;
      &.top {
        top: -3px !important;
      }
    }
    > .right-side-out {
      margin-top: -2px;
    }
  }

  &:not(.framed):not(.multi):not(.slim) {
    height: 20px;
    background: linear-gradient(to right, $o2_color_fade 0% 100%) left bottom
      #fff no-repeat;
    background-size: 100% 1px;
    &.indicate-error {
      background: linear-gradient(to right, $o2_color_error 0% 100%) left bottom
        #fff no-repeat;
      background-size: 100% 3px;
    }
  }
  &.multi {
    min-height: 20px;
    background: linear-gradient(to right, $o2_color_fade 0% 100%) left bottom
      #fff no-repeat;
    background-size: 100% 1px;
    margin-top: 10px;
    &.indicate-error {
      background: linear-gradient(to right, $o2_color_error 0% 100%) left bottom
        #fff no-repeat;
      background-size: 100% 3px;
    }
  }

  &.framed {
    > input {
      font-size: 16px;
    }
    > .prefix {
      line-height: 1px;
      margin-top: 3px;
      color: $o2_color_fade;
      font-size: 11px;
    }
    > span.validation {
      top: -5px;
      right: 5px !important;
    }
  }

  span.label {
    position: absolute;
    will-change: opacity;
    &.top {
      animation: semiFadeIn linear 0.3s;
      animation-fill-mode: forwards;
      top: -20px;
      font-size: 12px;
    }

    &:not(.top) {
      cursor: pointer;
    }
  }

  & > .label {
    white-space: nowrap;
  }
  & > input {
    text-indent: 2px;
  }
  & > .toolbar {
    display: flex;
    height: 100%;
    & > .ondemand {
      display: none;
    }
    & > * {
      margin-left: 5px;
      cursor: pointer;
      & > .permanent {
        opacity: 0.5;
      }
      & > :not(.permanent) {
        opacity: 0;
      }
    }
  }
  & > .popup {
    top: 21px !important;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%);
    background: white;
    max-height: 300px;
  }

  &:hover {
    & > .toolbar {
      & > .ondemand {
        display: flex;
      }
      & > * > :not(.permanent) {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
      & > * > *.permanent {
        opacity: 1;
      }
    }
  }
}

.mock-control {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 222px 20px;
  o2-search {
    width: 230px !important;
  }
  & > span,
  .link {
    width: 222px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

dropdown {
  &.framed {
    input {
      font-size: 14px;
    }
  }
  & > .popup {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      opacity: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      /* background: #f1f1f1; */
      background-color: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    > * {
      padding: 5px;
      &:hover {
        background-color: $o2_color_fader;
      }
      &.selected {
        background-color: $o2_color_fade;
        color: #fff;
      }
    }
  }
}

date {
  & > .popup {
    font-size: 13px;
    > .popup-bar {
      > * {
        &:hover {
          background-color: $o2_color_fader;
          cursor: pointer;
        }
      }
    }
    > .popup-body {
      > span {
        color: $o2_color_fade;
      }
      > span.selected {
        background-color: $o2_color_main;
        color: #fff;
      }
      > span.active:not(.selected) {
        color: $o2_color_main;
        &:hover {
          cursor: pointer;
          background-color: $o2_color_fader;
        }
      }
    }
  }
}

text {
  textarea {
    background-color: inherit;
  }
}

button {
  & > fa-icon {
    display: flex;
    color: $o2_color_main;
    font-size: 14px;
    opacity: 0.5;
  }

  &:hover {
    & > fa-icon {
      opacity: 1;
    }
  }

  &.framed.Ready {
    opacity: 0.5;
  }

  &.framed.IsDirty {
    opacity: 1;
  }
}

check.toggle {
  > .toggle-container > input {
    cursor: pointer;
    /* transition: all 0.2s ease; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    background-color: #fafafa;
    border: 1px solid transparent;
    bottom: -3px;
    border-radius: 60px;
    padding: 9px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0px 0px 2px 0 rgba(0, 0, 0, 0.12);
    &:not(:checked) {
      left: -12px;
      background-color: #e8e7e7;
    }
    &:checked {
      right: -8px;
      background-color: #fff;
    }
    &:checked:after {
      content: '\2714';
      position: absolute;
      color: $o2_color_main;
      top: -1px;
      left: 3px;
      font-size: 14px;
    }
  }
  > .toggle-container > label {
    -webkit-user-select: none;
    user-select: none;
    font-size: 11px;
    height: 16px;
    border: 1px Solid transparent;
    margin-left: -3px;
    padding-top: 1px;
    padding-bottom: 1px;
    > .toolbar {
      position: absolute;
      flex-direction: column;
      display: none;
      top: 5px;
      width: 18px;
      height: 11px;
    }
    &:hover {
      > .toolbar {
        display: flex;
      }
    }
    &:not(.checked) {
      padding-left: 16px;
      padding-right: 5px;
      background-color: #e8e7e7;
      color: rgb(177, 177, 177);
      > .toolbar {
        right: 3px;
        background-color: #e8e7e7;
      }
    }
    &.checked {
      padding-left: 5px;
      padding-right: 16px;
      background-color: $o2_color_main;
      color: #fff;
      > .toolbar {
        left: 0px;
        background-color: $o2_color_main;
        > history > span.info {
          color: #fff !important;
        }
      }
    }
    border-radius: 40px;
    line-height: 15px;
  }
}

periodic-stream {
  &.disabled {
    spreadsheet {
      user-select: none;
      pointer-events: none;
    }
  }
}

spreadsheet {
  &.disabled {
    user-select: none;
    pointer-events: none;
  }
  /* width: 100%; */
  td {
    position: relative;
    color: $o2_color_main;
  }
  td.disabled {
    background-color: #f1f1f1;
    color: $o2_color_disabled;
  }
  td.dirty {
    &::before {
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid $o2_color_fade;
    }
  }
  td.inherited {
    color: $o2_color_warning;
  }
  td.error {
    color: $o2_color_error;
    &::before {
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid $o2_color_error;
    }
  }
}

o2-search {
  > input {
    text-align: left;
  }
  .auto {
    width: auto;
  }
  .o2-search-list {
    z-index: 101;
    width: auto;
  }
}

.btn-strip {
  display: flex;
  flex-direction: row;
  * + * {
    margin-left: 10px;
  }
}

.sheet-bar-item {
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  & + .sheet-bar-item {
    margin-left: 15px;
  }
  &:last-of-type {
    margin-right: 15px;
  }
  & .btn {
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.header {
      padding-top: 2px;
    }
  }
}

.tag {
  display: flex;
  flex-direction: row;
  position: relative;
  text-align: center;
  width: 58px;

  &.OpenEnd {
    border-color: $o2_color_open_end;
    background-color: $o2_color_open_end;
    &::after {
      border-left-color: $o2_color_open_end;
    }
  }
  &.Unknown {
    border-color: lightgrey;
    background-color: lightgrey;
    color: grey;
    &::after {
      border-left-color: lightgrey;
    }
  }
  &.ClosedEnd {
    border-color: $o2_color_closed_end;
    background-color: $o2_color_closed_end;
    &::after {
      border-left-color: $o2_color_closed_end;
    }
  }
  &.Hybrid {
    border-color: $o2_color_closed_end;
    background: linear-gradient(
      90deg,
      $o2_color_open_end 50%,
      $o2_color_closed_end 50%
    );
    &::after {
      border-left-color: $o2_color_closed_end;
    }
  }
  border: 1px Solid;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  padding: 0px;
  color: #fff;
  font-size: 9px;
  opacity: 0.7;
  &::before {
    position: absolute;
    content: '';
    right: 0px;
    width: 2px;
    height: 2px;
    background-color: #fff;
    border-radius: 40px;
  }
  &::after {
    position: absolute;
    right: -6px;
    content: '';
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 5px solid;
  }
}

popover {
  min-width: 500px;
  .header {
    &:not(.warning) {
      padding: 20px;
      color: #d4d4d4;
    }
    &.warning {
      padding: 20px 20px 0px;
      color: $o2_color_warning;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    border-radius: 3px;
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    &.no-content,
    &.loading {
      align-items: center;
      justify-content: center;
      color: #d4d4d4;
      background: none;
      letter-spacing: 4px;
      font-variant: all-petite-caps;
      font-weight: 500;
    }
    &.json {
      height: 900px;
      width: 930px;
    }
  }
}

.note {
  color: $o2_color_fade;
  font-size: 12px;
}
.subnote {
  color: $o2_color_main;
  font-size: 11px;
  font-style: italic;
}
.separator {
  border-bottom: 1px Solid whitesmoke;
}
.saveRemaining {
  display: none;
}

/*#endregion - NEW Controls - */

dropdown-v2,
date-v2,
text-v2,
number-v2 {
  &.hasError {
    * {
      color: $o2_color_error !important;
    }
  }
}

@keyframes semiFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
