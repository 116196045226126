textarea {
  resize: none;
  padding: 0px;
  height: 20px;
  line-height: 20px;
}

text-v2,
number-v2 {
  .ui-header,
  .ui-body {
    cursor: text;
  }
}

text {
  &.in-popover {
    height: 100% !important;
    textarea {
      min-height: 80px;
      height: auto !important;
      padding: 5px;
      background-color: lightgrey;
    }
  }
}

badge {
  position: relative;
  .ui-list {
    display: grid;
    grid-auto-rows: 30px;
    grid-auto-columns: 100%;
    justify-content: start;
    align-items: center;
    justify-items: stretch;
    position: absolute;
    z-index: 1;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    min-width: 100%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

    max-height: 210px;
    overflow-y: auto;
    overflow-x: hidden;
    li.selected {
      background-color: $o2_color_fade;
      color: #fff;
    }
  }
}

dropdown-v2 {
  .ui-header,
  .ui-body:read-only {
    cursor: pointer;
  }
  .ui-list {
    position: relative;
    max-height: 210px;
    overflow-y: auto;
    overflow-x: hidden;
    li.selected {
      background-color: $o2_color_fade;
      color: #fff;
    }
    &.uniqueViolation::after {
      content: 'This value is already selected once';
      position: absolute;
      top: -21px;
      width: 100%;
      color: $o2_color_error;
      background-color: #fff;
      font-size: smaller;
    }
  }
}

date-v2 {
  .ui-header,
  .ui-body:read-only {
    cursor: pointer;
  }

  .ui-calendar {
    /* top: 21px !important;  */
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%);
    background: white;
    max-height: 300px;
    font-size: 13px;

    &.Top {
      top: inherit;
      bottom: 0px;
    }

    > .ui-calender-bar {
      > * {
        &:hover {
          background-color: $o2_color_fader;
          cursor: pointer;
        }
      }
    }
    > .ui-calendar-body {
      > span {
        color: $o2_color_fade;
      }
      > span.selected {
        background-color: $o2_color_main;
        color: #fff;
      }
      > span.active:not(.selected) {
        color: $o2_color_main;
        &:hover {
          cursor: pointer;
          background-color: $o2_color_fader;
        }
      }
    }
  }
}

check-v2 {
  .ui-control {
    grid-template-columns: 20px auto 20px !important;
    grid-template-rows: 20px !important;
    .ui-header,
    .ui-body,
    .ui-toolbar {
      grid-row: 1 !important;
    }
    .ui-body {
      grid-column: 1;
    }
    .ui-header {
      position: relative !important;
      top: 0px !important;
      grid-column: 2 !important;
      text-align: center;
      &.focused {
        animation: none;
        font-size: 11px;
      }
    }
    .ui-toolbar {
      display: none;
      > * {
        padding: 0px !important;
      }
      background: none !important;
      align-self: center;
      justify-self: center;
      &.checked {
        grid-column: 1;
      }
      &:not(.checked) {
        grid-column: 3;
      }
    }
    &:hover {
      .ui-toolbar {
        display: grid;
      }
    }
    > .ui-body {
      position: relative;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid transparent;
      border-radius: 60px;
      margin: 0px;
      padding: 0px;
      box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0px 0px 2px 0 rgba(0, 0, 0, 0.12);
      &:not(.checked) {
        grid-column: 1 !important;
        left: 10px;
        background-color: #e8e7e7;
      }
      &.checked {
        grid-column: 3 !important;
        right: 10px;
        background-color: #fff;
      }
      &.checked:after {
        content: '\2714';
        position: absolute;
        color: $o2_color_main;
        font-size: 14px;
        left: 3px;
        top: -1px;
      }
    }
    > label-v2 {
      -webkit-user-select: none;
      user-select: none;
      font-size: 11px;
      height: 14px;
      border: 1px Solid transparent;
      justify-self: stretch;
      border-radius: 40px;
      line-height: 16px;
      border-radius: 40px;
      line-height: 16px;
      align-self: center;

      &:not(.checked) {
        background-color: #e8e7e7;
        color: rgb(177, 177, 177);
      }
      &.checked {
        background-color: $o2_color_main;
        color: #fff;
      }
    }
  }
}

.insubtitle {
  > .ui-control {
    grid-template-rows: 20px !important;
    align-items: baseline;
    > * {
      grid-row: 1;
    }
    .ui-header {
      grid-row: 1;
    }
    > .ui-body {
      background-color: transparent !important;
    }
  }
}

button,
dropdown-v2.framed {
  &.HasErrors {
    > * {
      color: $o2_color_error;
    }
  }
  &.HasWarnings {
    > * {
      color: $o2_color_warning;
    }
  }
}

.framed {
  & fa-icon {
    font-size: 16px;
  }

  &.circle {
    //TODO: delete?
    border-radius: 40px;
    min-height: auto;
    min-width: 22px;
  }

  border-radius: 3px;
  background-color: #fff;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #fff;
  box-shadow: $box-shadow;
  //min-height: 30px;
  color: #696d7d;
  padding: 10px 10px;

  &[tools] {
    padding: 5px 10px;
  }

  &.Loading,
  &.Saving {
    background: linear-gradient(to right, #fff 0% 50%, $o2_color_main 50% 100%)
      left bottom #fff repeat-x;
    background-size: 200% 3px;
    animation: h-loading 1.5s linear infinite;
  }

  &.leftHandle {
    &.alwaysOn {
      background: linear-gradient(
        to right,
        $o2_color_fader 0 5px,
        #fff 5px 100%
      );
    }
    &:hover {
      background: linear-gradient(
        to right,
        $o2_color_main 0 5px,
        #fff 5px 100%
      );
    }
  }
  &:hover:not(.leftHandle) {
    background: linear-gradient(to right, $o2_color_main 0% 100%) left bottom
      #fff no-repeat;
    background-size: 100% 3px;
    &.NotSaved,
    &.indicate-error {
      background: linear-gradient(to right, $o2_color_error 0% 100%) left bottom
        #fff no-repeat;
      background-size: 100% 3px;
    }
    &.Saved {
      background: linear-gradient(to right, $o2_color_success 0% 100%) left
        bottom #fff no-repeat;
      background-size: 100% 3px;
    }
    &.IsDirty {
      background: linear-gradient(to right, $o2_color_main 0% 100%) left bottom
        #fff no-repeat;
      background-size: 100% 3px;
    }
  }

  &:hover {
    > .ui-control {
      .ui-footer {
        grid-column: 2 / span 22;
      }
    }
  }

  &.single-line {
    height: 20px;
    > .ui-control {
      grid-template-rows: 0px 20px !important;
    }

    label-v2 {
      &.focused {
        display: none;
      }
      top: 0px !important;
    }
  }

  > .ui-control {
    grid-template-rows: 20px 20px !important;
    justify-items: stretch;

    .ui-header.focused {
      animation: semiFadeIn linear 0.3s;
      animation-fill-mode: forwards;
      font-size: 12px;
    }

    .ui-body,
    .ui-toolbar {
      background: none;
    }

    .ui-footer {
      grid-row: 1;
      grid-column: 2 / span 21;
      font-size: 11px;

      > span {
        &.Soft {
          color: $o2_color_warning;
        }
        &.Hard {
          color: $o2_color_error;
        }
      }
    }

    .ui-icon {
      opacity: 0.5;
      cursor: pointer;
    }

    .ui-toolbar > * {
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .ui-control.editable:not(.isEditing) {
    .ui-toolbar:not(.Pinned) {
      display: none;
    }
  }
  .ui.control.isEditing {
    .ui-toolbar {
      display: grid;
    }
  }
  &:hover {
    .ui-control {
      .ui-icon {
        opacity: 1;
      }
    }
    .ui-control.editable {
      .ui-toolbar:not(.Pinned) {
        display: grid;
      }
    }
  }
}

*:not(.framed):not(.insubtitle):not(.check) {
  > .ui-control {
    .ui-body,
    .ui-toolbar:not(.noline),
    .ui-icon:not(.noline) {
      background: linear-gradient(to right, rgba(105, 109, 125, 0.5) 0% 100%)
        left bottom #fff no-repeat;
      background-size: 100% 1px;
    }

    .ui-icon {
      color: $o2_color_fade;
      padding: 0 4px;
      &:not(.large) {
        font-size: 10px;
      }
      & > .large {
        font-size: 14px;
      }
    }

    .ui-body {
      &::placeholder {
        color: $o2_color_fade;
      }
    }

    .ui-header.focused {
      animation: semiFadeIn linear 0.3s;
      animation-fill-mode: forwards;
      font-size: 12px;
    }

    .ui-toolbar {
      &:not(.Pinned) {
        display: none;
      }
      &.Pinned {
        display: grid;
      }
      grid-template-rows: 20px;
      > * {
        grid-row: 1;
        width: 15px;
        text-align: center;
        align-items: center;
        justify-items: center;
      }
      > *:nth-child(1) {
        grid-column: -1;
      }
      > *:nth-child(2) {
        grid-column: -2;
      }
      > *:nth-child(3) {
        grid-column: -3;
      }
      > *:nth-child(4) {
        grid-column: -4;
      }
      > *:nth-child(5) {
        grid-column: -5;
      }
      > *:nth-child(6) {
        grid-column: -6;
      }
      > *:nth-child(7) {
        grid-column: -7;
      }
      span:not(.emphasize),
      fa-icon:not(.emphasize) {
        cursor: pointer;
        color: $o2_color_fade;
        &:hover {
          color: $o2_color_main;
        }
      }
    }

    .ui-footer {
      font-size: 10px;
      line-height: normal;

      > span {
        &.Soft {
          color: $o2_color_warning;
        }
        &.Hard {
          color: $o2_color_error;
        }
      }
    }

    &.HasError {
      color: $o2_color_error;
      .ui-body,
      .ui-toolbar,
      .ui-icon {
        background: linear-gradient(to right, $o2_color_error 0% 100%) left
          bottom #fff no-repeat;
        background-size: 100% 1px;
      }
    }
  }
  &:hover {
    > .ui-control {
      .ui-toolbar:not(.Pinned) {
        display: grid;
      }
      .ui-icon {
        color: $o2_color_main;
        cursor: pointer;
      }
    }
  }
}

.ui-control.disabled,
button:disabled {
  opacity: 0.6;

  *:not(.ui-icon) {
    cursor: not-allowed !important;
  }

  .ui-toolbar,
  .ui-icon {
    cursor: pointer !important;
    * {
      cursor: pointer !important;
    }
  }
}

label-v2 {
  span.label {
    user-select: text;
  }
}

.pac-container {
  width: auto !important;
}
