text-v2{
    .ui-control {
        grid-template-rows: 20px minmax(auto,20px) 20px !important;
    }
}

.ui-control {
    position: relative;
    display:grid;
    grid-template-rows: 20px 20px 20px;

    &.grow-y {
        grid-template-rows: 20px auto 20px;
    }
    
    .ui-footer {
        grid-column: 2 / span 20;
    }

    &:hover {
        .ui-footer {
            grid-column: 2 / span 21;
        }
    }

    .ui-list-filter {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        height: 30px;
        position: absolute;
        padding: 0 5px;
        top: 0;
        z-index: 1;
        grid-row: 3 / span 1;

        input{
            flex-grow: 2;
        }
    }

    .ui-list {
        display: grid;
        grid-auto-rows: 30px;
        grid-auto-columns: 100%;
        justify-content: start;
        align-items:center;
        justify-items: stretch;
        position: absolute;
        z-index: 1;
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        background-color: #fff;
        min-width: 100%;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

        &.Top {
            bottom: 0px;
            grid-row: 1 / span 1;
        }

        &.Bottom {
            top: 0px;
            grid-row: 3 / span 1;

            /* &.canFilter{
                grid-row:  4 / span 1;
            } */
        }

        &.Right {
            right: 0px;
        }

        &.Left {
            left: 0px;
        }

        &.canFilter{
            box-shadow: 0 5px 4px -1px rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.14), 0 5px 10px 0 rgba(0, 0, 0, 0.12);
        }

        > li {
            justify-self: stretch;
            padding: 5px 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        > li:hover {
            background-color: lightgrey;
        }
    }

    &.Top {
        grid-template-columns: auto;
        .ui-header { 
            //grid-column: 1 / span 20;
            grid-row: 1 /span 1;
        }
        .ui-body {
            grid-column: 1 / span 20;
        }
    }
    &.Left {
        grid-template-columns: auto 1fr;
        grid-template-rows: 20px;

        .ui-header { 
            //grid-column: 1 / span 1;
            grid-row: 2 /span 1;
            margin-right: 5px;
        }
        .ui-body {
            grid-column: 2 / span auto;
            background: #fff !important;
            
        }
        .ui-toolbar, .ui-icon {
            background: #fff !important;
        }
        validation-v2 {
            position: absolute
        }

    }
    &.Bottom {
        grid-template-columns: auto;
        .ui-header { 
            //grid-column: 1 / span 20;
            grid-row: 3 /span 1;
        }
        .ui-body {
            grid-column: 1 / span 20;
        }
    }
    &.Right {
        grid-template-columns: 1fr auto;
        .ui-header { 
            //grid-column: 3 / span 1;
            grid-row: 2 /span 1;
        }
        .ui-body {
            grid-column: 1 / span auto;
        }
        .ui-toolbar {
            grid-column: 2 / span 1;
        }
    }
    &.Embedded {
        grid-template-columns: 1fr;
        .ui-header { 
            overflow:hidden;
            grid-row: 2 /span 1;
            grid-column: 1 / 20;
            position: absolute;
            text-overflow: ellipsis;
            width: 100%;

            &:not(.focused) {
                top: 0px;
            }
            &.focused {
                top: -16px;
            }
            > * {
                white-space: nowrap;
            }
        }
        .ui-body {
            grid-column: 1 / span 20;
        }
    }
    &:focus.Embedded {
            top: 0px !important;
    }

    .ui-toolbar {
        display: grid;
        grid-gap: 2px;
    }

    .ui-toolbar {
        padding-right: 2px;
    }

    > input, textarea {
        background-color: #fff;
        color: inherit;
        text-overflow: ellipsis;
    }
    
}

